<template>
  <div class="">
    <div class="heading-sec">
      <div class="col-md-4 mt-6">
        <div class="poppins-medium font-30 heading-profile ml-7">My Size & Fit</div>
      </div>
    </div>
    <div class="panel-content bg-white border">
      <div class="row">
        <div class="col-md-12 mt-4">
          <div class="font-20 widget-title float-left ml-10">
            List of My Size & Fit Profiles
            <span v-if="sizeProfiles && sizeProfiles" class="tableTotal font-15 poppins-bold"
              >(Total : {{ sizeProfiles.length }})</span
            >
          </div>
          <div class="float-right d-flex mx-4">
            <div>
              <v-dialog v-model="dialog" width="50%">
                <template v-slot:activator="{ on, attrs }">
                  <b-button variant="secondary" v-bind="attrs" v-on="on">
                    Filter
                    <b-icon-filter></b-icon-filter>
                  </b-button>
                </template>

                <v-card>
                  <div class="form-elements-sec">
                    <div class="form-body">
                      <form name="details" class="formPart text-left">
                        <div>
                          <div class="form-group mx-4 pt-4">
                            <label for="name">Name</label>
                            <input
                              type="name"
                              class="form-control"
                              id="name"
                              placeholder="Enter Name"
                              v-model="filter.name"
                            />
                          </div>
                        </div>

                        <!-- <div>
                          <div class="form-group mx-4 pt-4">
                            <label for="mobile">Quantity</label>
                            <input
                              type="number"
                              class="form-control"
                              id="mobile"
                              placeholder="Enter Quantity"
                              v-model="filter.quantity"
                            />
                          </div>
                        </div> -->

                        <v-row class="mt-3 mx-2">
                          <!-- <v-col class="">
                            <v-select
                              :items="categories"
                              label="Category"
                              dense
                              solo
                              v-model="filter.category"
                            ></v-select>
                          </v-col> -->
                          <v-col class="">
                            <v-select
                              :items="gender"
                              label="Gender"
                              dense
                              solo
                              v-model="filter.gender"
                            ></v-select>
                          </v-col>
                        </v-row>

                        <div class="btnPart text-center py-4">
                          <button
                            @click="getUserSizeProfiles()"
                            type="submit"
                            class="btn btn-success px-6"
                          >
                            Save
                          </button>

                          <button type="reset" class="btn btn-danger mx-2" v-on:click="resetForm()">
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </v-card>
              </v-dialog>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-center" align="center">
        <div class="col-md-12">
          <div class="widget">
            <div class="table-area">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th class="col-md-1 text-center">#</th>

                      <th class="col-md-3">Date</th>

                      <th class="col-md-3">Name</th>

                      <th class="colo-md-2">Gender</th>
                      <th class="colo-md-2">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in sizeProfiles" v-bind:key="item._id">
                      <td class="text-center">
                        {{ index + 1 + (page - 1) * 20 }}
                      </td>

                      <td>
                        <div v-if="item.createdAt">{{ item.createdAt | moment }}</div>
                      </td>
                      <td>
                        <div v-if="item.name">{{ item.name }}</div>
                      </td>
                      <td>
                        <div v-if="item.gender">{{ item.gender }}</div>
                      </td>

                      <td class="text-left">
                        <div class="d-flex">
                          <div class="mr-6">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  min-width="40px"
                                  height="40px"
                                  color="green"
                                  @click="editSizeProfile(item._id)"
                                  v-bind="attrs"
                                  v-on="on"
                                  append
                                >
                                  <b-icon-pencil class="text-white"></b-icon-pencil>
                                </v-btn>
                              </template>
                              <span>Edit</span>
                            </v-tooltip>
                          </div>

                          <!-- <div class="mr-6">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  min-width="40px"
                                  height="40px"
                                  color="red"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="deleteConfirm(item._id)"
                                >
                                  <b-icon-trash color="white"></b-icon-trash>
                                </v-btn>
                              </template>
                              <span>Delete</span>
                            </v-tooltip>
                          </div> -->
                        </div>
                      </td>
                      <!-- v-repeat -->
                    </tr>
                    <div></div>
                    <!-- end v-repeat -->
                  </tbody>
                </table>
              </div>
              <div
                class="table-body-contents text-center m-3 font-size-md font-weight-bold text-muted"
                v-if="sizeProfiles.length == 0"
              >
                No Data Found
              </div>

              <!-- Use text in props -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center mt-4" v-if="sizeProfiles">
      <v-pagination
        class="mb-2 float-center"
        v-model="page"
        :length="totalPages"
        @input="getUserSizeProfiles()"
        :per-page="20"
        prev-icon="<"
        next-icon=">"
      />
    </div>
    <v-dialog
      v-model="editSizeFlag"
      width="80%"
      :height="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 'auto' : '90vh'"
      :fullscreen="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? true : false"
      class="d-flex sizeProfileDialog"
      overlay-color="black"
      overlay-opacity="1"
    >
      <v-card
        width="1084"
        :height="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 'auto' : '80vh'"
        class="justify-center size-card"
        align="center"
      >
        <v-row no-gutters class="pt-2 mb-0">
          <v-col cols="9"></v-col>
          <v-col cols="3">
            <v-btn
              icon
              bright
              class="float-right mr-6 size-dialog-close-icon"
              @click="editSizeFlag = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn></v-col
          >
        </v-row>

        <v-row no-gutters class="">
          <v-col cols="12" lg="6" md="6" sm="12" class="measurement-section mt-3 mt-md-0 mb-0">
            <v-form ref="form" @submit.prevent="validation">
              <div class="butler-700 font-22 text-black text-left px-4 mx-md-8">
                Edit Your Measurements
              </div>
              <v-expansion-panels v-model="panel" class="mx-md-5" flat accordion>
                <v-expansion-panel
                  v-for="item in Items"
                  :key="item.id"
                  class="mt-2 px-4 px-md-2 py-0"
                >
                  <v-expansion-panel-header>
                    {{ item.name }}
                    <template v-slot:actions>
                      <!-- <v-icon v-if="item.id == panel" color="#5F5F5F"
                        >$minus</v-icon
                      >
                      <v-icon v-else color="#5F5F5F">$plus</v-icon> -->
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content
                    v-for="(pitem, key, index) in item.subItem"
                    :key="index"
                    class="mx-md-10 mr-3 px-4 px-0"
                    dense
                  >
                    <div class="mt-2 mb-2 text-left maroon-txt poppins-500">
                      {{ pitem.title }}
                    </div>
                    <v-text-field
                      v-model="pitem.value"
                      suffix="inches"
                      :rules="[
                        (v) => !!v || 'Field is required',
                        (v) => /^[0-9]*$/.test(v) || 'Only positive numbers are allowed',
                      ]"
                      type="number"
                    ></v-text-field>

                    <!-- <v-expansion-panels>
                      <v-expansion-panel
                        v-for="(pitem, i) in item.subItem"
                        :key="i"
                      >
                        <v-expansion-panel-header>
                          {{ pitem.title }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="my-4 mx-4">
                          {{ pitem.title }}

                          <v-text-field v-model="pitem.value"></v-text-field>
                        </v-expansion-panel-content>
                        <v-divider></v-divider>
                      </v-expansion-panel>
                    </v-expansion-panels> -->
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel class="px-2">
                  <v-expansion-panel-header>
                    Additional Notes
                    <template v-slot:actions> </template>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content class="mx-md-10 mr-3 px-4 px-0" dense>
                    <div class="poppins-grey-500 pb-6">
                      Please add any additional notes related to your preferred fit (Eg. Prefer a
                      looser fit by 1 inches or prefer a longer top etc)
                    </div>
                    <v-textarea
                      v-model="additionalNotes"
                      rows="6"
                      cols="80"
                      row-height="20"
                    ></v-textarea>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <div class="d-md-flex justify-space-between mt-16 mx-md-5">
                <v-text-field
                  outlined
                  placeholder="Name"
                  dense
                  class="mx-12 mx-4"
                  height="40"
                  v-model="sizeProfileName"
                  :rules="[(v) => !!v || 'Field is required']"
                ></v-text-field>
                <v-btn
                  class="
                    font-16
                    poppins-medium
                    black-btn
                    mx-2
                    mt-8 mt-md-0
                    mb-5 mb-md-0
                    text-center
                  "
                  height="40"
                  type="submit"
                >
                  Save Changes</v-btn
                >
              </div>
            </v-form>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" class="mt-3 mt-md-0">
            <div class="mx-md-8 px-4">
              <div
                class="butler-700 font-22 text-black text-left ml-md-8 mb-5"
                v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
              >
                Add Your Measurements
              </div>
              <div>
                <v-tabs
                  v-model="gender"
                  class="category-tab-section text-center"
                  fixed-tabs
                  height="35px"
                >
                  <v-tabs-slider color="#72593F" class="tabs-slider-none"></v-tabs-slider>
                  <v-tab
                    class="tag-title"
                    v-for="item in categories"
                    :key="item.index"
                    :href="'#' + item.name"
                  >
                    {{ item.tab }}
                  </v-tab>
                  <!-- <v-tab class="tag-title"> WOMEN </v-tab>
            <v-tab class="tag-title"> MEN </v-tab> -->
                </v-tabs>
              </div>
            </div>
            <div class="mx-4">
              <v-img v-if="gender === 'Male'" :src="MenImages[panel]" width="350" height="460">
              </v-img>
              <v-img v-else :src="WomenImages[panel]" width="350" height="460"> </v-img>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <ConfirmDlg ref="confirm" />
  </div>
</template>

<script>
import customerService from '../../services/customerService';
import ConfirmDlg from '../ConfirmDlg.vue';
import MainImageMen from '@/assets/Images/MainPageMen.png';
import MainImageWomen from '@/assets/Images/MainPageWomen.png';
import MenArms from '@/assets/Images/MenArms.png';
import MenNeck from '@/assets/Images/MenNeck.png';
import MenChest from '@/assets/Images/MensChest.png';
import MenLegs from '@/assets/Images/MensLegs.png';
import MenLength from '@/assets/Images/MensLength.png';
import WomenArms from '@/assets/Images/WomenArms.png';
import WomenLegs from '@/assets/Images/WomenLegs.png';
import WomenLength from '@/assets/Images/WomenLength.png';
import WomenNeck from '@/assets/Images/WomenNeck.png';
import WomenChest from '@/assets/Images/womensChest.png';
import moment from 'moment';

export default {
  components: { ConfirmDlg },
  data() {
    return {
      searchText: '',
      sizeProfiles: [],

      page: 1,
      dialog: false,
      totalPages: '',

      filter: {
        category: '',
        name: '',
        quantity: '',
        gender: '',
      },

      editSizeFlag: false,
      gender: '',
      name: '',
      menItems: [
        {
          name: 'Neck',
          id: 0,
          subItem: [
            {
              title: 'Neck Circumference',
              value: '',
              key: 'neckCircumference',
            },

            {
              title: 'Across Shoulder',
              value: '',
              key: 'acrossShoulder',
            },
            {
              title: 'Armhole',
              value: '',
              key: 'armHole',
            },
            {
              title: 'Shirt Length(Shoulder to Hips)',
              value: '',
              key: 'shirtShoulderToHipLength',
            },
          ],
        },
        {
          name: 'Chest',
          id: 1,
          subItem: [
            {
              title: 'Upper Chest',
              value: '',
              key: 'upperChest',
            },
            {
              title: 'Chest',
              value: '',
              key: 'chest',
            },

            {
              title: 'Waist',
              value: '',
              key: 'waist',
            },
            {
              title: 'Stomach',
              value: '',
              key: 'stomach',
            },
          ],
        },
        {
          name: 'Arms',
          id: 2,
          subItem: [
            {
              title: 'Bicep Circumference',
              value: '',
              key: 'bicepCircumference',
            },
            {
              title: 'Above Elbow',
              value: '',
              key: 'aboveElbow',
            },
            {
              title: 'Wrist',
              value: '',
              key: 'wrist',
            },
            {
              title: 'Shoulder Point to Wrist',
              value: '',
              key: 'shoulderPointToWrist',
            },
            {
              title: 'Shoulder Point to Elbow',
              value: '',
              key: 'shoulderPointToElbow',
            },
          ],
        },
        {
          name: 'Legs',
          id: 3,
          subItem: [
            {
              title: 'Hips',
              value: '',
              key: 'hips',
            },
            {
              title: 'Upper Thigh',
              value: '',
              key: 'upperThigh',
            },
            {
              title: 'Above Knee',
              value: '',
              key: 'aboveKnee',
            },
            {
              title: 'Calf',
              value: '',
              key: 'calf',
            },
            {
              title: 'Ankle',
              value: '',
              key: 'ankle',
            },
            {
              title: 'Inseam',
              value: '',
              key: 'inseam',
            },
          ],
        },
        {
          name: 'Length',
          id: 4,
          subItem: [
            {
              title: 'Shoulder to Thigh',
              value: '',
              key: 'shoulderToThigh',
            },
            {
              title: 'Shoulder to Knee',
              value: '',
              key: 'shoulderToKnee',
            },
            {
              title: 'Shoulder to Calf',
              value: '',
              key: 'shoulderToCalf',
            },
            {
              title: 'Total Height',
              value: '',
              key: 'shoulderToAnkle',
            },
            {
              title: 'Bottoms Length (Waistband to Floor)',
              value: '',
              key: 'bottomsWaistToFloor',
            },
          ],
        },
      ],
      womenItems: [
        {
          name: 'Neck',
          id: 0,
          subItem: [
            {
              title: 'Neck Circumference',
              value: '',
              key: 'neckCircumference',
            },
            {
              title: 'Neckline',
              value: '',
              key: 'neckLine',
            },
            {
              title: 'Across Shoulder',
              value: '',
              key: 'acrossShoulder',
            },
            {
              title: 'Armhole',
              value: '',
              key: 'armHole',
            },
            {
              title: 'Shoulder to Bust Point',
              value: '',
              key: 'shoulderToBustPoint',
            },
            {
              title: 'Blouse Length (Shoulder to Waist)',
              value: '',
              key: 'blouseLengthShoulderToWaist',
            },
          ],
        },
        {
          name: 'Chest',
          id: 1,
          subItem: [
            {
              title: 'Upper Chest',
              value: '',
              key: 'upperChest',
            },
            {
              title: 'Bust',
              value: '',
              key: 'bust',
            },
            {
              title: 'Under Bust',
              value: '',
              key: 'underBust',
            },
            {
              title: 'Waist',
              value: '',
              key: 'waist',
            },
            {
              title: 'Stomach',
              value: '',
              key: 'stomach',
            },
          ],
        },
        {
          name: 'Arms',
          id: 2,
          subItem: [
            {
              title: 'Bicep Circumference',
              value: '',
              key: 'bicepCircumference',
            },
            {
              title: 'Above Elbow',
              value: '',
              key: 'aboveElbow',
            },
            {
              title: 'Wrist',
              value: '',
              key: 'wrist',
            },
            {
              title: 'Shoulder Point to Wrist',
              value: '',
              key: 'shoulderPointToWrist',
            },
            {
              title: 'Shoulder Point to Elbow',
              value: '',
              key: 'shoulderPointToElbow',
            },
            {
              title: 'Shoulder Point to Bicep',
              value: '',
              key: 'shoulderPointToBicep',
            },
          ],
        },
        {
          name: 'Legs',
          id: 3,
          subItem: [
            {
              title: 'Hips',
              value: '',
              key: 'hips',
            },
            {
              title: 'Upper Thigh',
              value: '',
              key: 'upperThigh',
            },
            {
              title: 'Above Knee',
              value: '',
              key: 'aboveKnee',
            },
            {
              title: 'Calf',
              value: '',
              key: 'calf',
            },
            {
              title: 'Ankle',
              value: '',
              key: 'ankle',
            },
          ],
        },
        {
          name: 'Length',
          id: 4,
          subItem: [
            {
              title: 'Shoulder to Waist',
              value: '',
              key: 'shoulderToWaist',
            },
            {
              title: 'Shoulder to Stomach',
              value: '',
              key: 'shoulderToStomach',
            },
            {
              title: 'Shoulder to Hip',
              value: '',
              key: 'shoulderToHip',
            },
            {
              title: 'Shoulder to Knee',
              value: '',
              key: 'shoulderToKnee',
            },
            {
              title: 'Shoulder to Floor Length',
              value: '',
              key: 'shoulderToFloor',
            },
            {
              title: 'Bottoms Length (Waistband to Floor)',
              value: '',
              key: 'bottomsWaistToFloor',
            },
          ],
        },
      ],
      Items: [],
      Neck: {},
      Chest: {},
      Arms: {},
      Legs: {},
      Length: {},

      additionalNotes: '',
      MenImages: [MainImageMen, MenNeck, MenChest, MenArms, MenLegs, MenLength],
      WomenImages: [MainImageWomen, WomenNeck, WomenChest, WomenArms, WomenLegs, WomenLength],
      //tab: null,

      panel: 0,
      sizeProfileName: '',
      categories: [
        { tab: 'MEN', name: 'Male' },
        { tab: 'WOMEN', name: 'Female' },
      ],
      selectedSizeProfile: {},
    };
  },
  async created() {
    await this.getUserSizeProfiles();
  },

  methods: {
    async transformData() {
      console.log(this.Items, 'item-here');
      await _.each(this.Items, (key) => {
        switch (key.name) {
          case 'Neck':
            for (const [k, v] of Object.entries(this.selectedSizeProfile.neck)) {
              key.subItem.forEach((item) => {
                if (k == item.key) {
                  item.value = v;
                }
              });
            }

            break;
          case 'Chest':
            for (const [k, v] of Object.entries(this.selectedSizeProfile.chest)) {
              key.subItem.forEach((item) => {
                if (k == item.key) {
                  item.value = v;
                }
              });
            }
            break;
          case 'Arms':
            for (const [k, v] of Object.entries(this.selectedSizeProfile.arm)) {
              key.subItem.forEach((item) => {
                if (k == item.key) {
                  item.value = v;
                }
              });
            }
            break;
          case 'Legs':
            for (const [k, v] of Object.entries(this.selectedSizeProfile.legs)) {
              key.subItem.forEach((item) => {
                if (k == item.key) {
                  item.value = v;
                }
              });
            }
            break;
          case 'Length':
            for (const [k, v] of Object.entries(this.selectedSizeProfile.length)) {
              key.subItem.forEach((item) => {
                if (k == item.key) {
                  item.value = v;
                }
              });
            }
        }
      });
      this.tab = this.selectedSizeProfile.gender;
      this.sizeProfileName = this.selectedSizeProfile.name;
      this.additionalNotes = this.selectedSizeProfile.additionalNotes;
    },
    async getUserSizeProfiles() {
      this.dialog = false;
      const data = {};
      data.page = this.page;
      data.filter = this.filter;
      const result = await customerService.getUserAllSizeProfiles(this.$route.params.id, data);
      this.sizeProfiles = [];
      this.sizeProfiles = result.data.data;
      this.totalPages = result.data.paginationData.totalPages;
      console.log('result', result);
      console.log('this.route', this.$router.path);
    },
    resetForm() {
      this.filter = {
        category: '',
        name: '',
        quantity: '',
        gender: '',
      };
      this.dialog = false;
      this.getUserSizeProfiles();
    },
    async editSizeProfile(id) {
      this.editSizeFlag = true;
      this.selectedSizeProfile = await this.sizeProfiles.filter((item) => item._id === id);
      this.selectedSizeProfile = this.selectedSizeProfile[0];
      console.log(this.selectedSizeProfile, 'selectedSize-profile');
      if (this.selectedSizeProfile.gender == 'Male') {
        this.Items = await this.menItems;
      } else {
        this.Items = await this.womenItems;
      }
      this.gender = this.selectedSizeProfile.gender;
      await this.transformData();
    },
    async validation() {
      await this.transformDataValidation();

      if (this.$refs.form.validate()) {
        // form is valid, do something
        await this.updateSize();
      } else {
        await this.$store.commit('setSnackbar', {
          content: 'Please fill the form correctly!',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      }
    },
    transformDataValidation() {
      this.Items.forEach((key) => {
        switch (key.name) {
          case 'Neck':
            key.subItem.forEach((item) => {
              this.Neck[item.key] = parseInt(item.value);
            });
            break;
          case 'Chest':
            key.subItem.forEach((item) => {
              this.Chest[item.key] = parseInt(item.value);
            });
            break;
          case 'Arms':
            key.subItem.forEach((item) => {
              this.Arms[item.key] = parseInt(item.value);
            });
            break;
          case 'Legs':
            key.subItem.forEach((item) => {
              this.Legs[item.key] = parseInt(item.value);
            });
            break;
          case 'Length':
            key.subItem.forEach((item) => {
              this.Length[item.key] = parseInt(item.value);
            });
        }
      });
      console.log(this.Neck, 'NECK');
      console.log(this.Chest, 'Chest');
      console.log(this.Arms, 'Arms');
      console.log(this.Legs, 'Legs');
      console.log(this.Length, 'Length');
    },
    async updateSize() {
      this.selectedSizeProfile.neck = this.Neck;
      this.selectedSizeProfile.chest = this.Chest;
      this.selectedSizeProfile.arm = this.Arms;
      this.selectedSizeProfile.legs = this.Legs;
      this.selectedSizeProfile.length = this.Length;
      this.selectedSizeProfile.name = this.sizeProfileName;
      this.selectedSizeProfile.gender = this.gender;
      this.selectedSizeProfile.additionalNotes = this.additionalNotes;
      const result = await customerService.updateUserSizeProfileData(
        this.selectedSizeProfile._id,
        this.selectedSizeProfile
      );
      if (result.status == 200) {
        this.$store.commit('setSnackbar', {
          content: 'Size Profile Saved!',
          icon: 'mdi-check-circle',
          color: 'success ',
          isVisible: true,
        });
        this.editSizeFlag = false;
        await this.getUserSizeProfiles();
        this.$forceUpdate();
      } else {
        this.$store.commit('setSnackbar', {
          content: 'An error occured while trying to update the size!',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      }
    },
    async deleteSizeProfile(id) {
      const result = await customerService.deleteUserSizeProfile(id);
      if (result.status == 200) {
        this.$store.commit('setSnackbar', {
          content: 'Size has been successfully deleted!',
          icon: 'mdi-check-circle',
          color: 'success ',
          isVisible: true,
        });
        await this.getUserSizeProfiles();
        this.$forceUpdate();
        this.deleteSizeDialog = false;
      } else {
        this.$store.commit('setSnackbar', {
          content: 'An error occured while trying to delete the size!',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      }
    },
    async deleteConfirm(id) {
      if (await this.$refs.confirm.open('Confirm', 'Are you sure you want to delete?')) {
        this.deleteSizeProfile(id);
      }
    },
  },
  filters: {
    moment(date) {
      return moment(date).format('DD-MM-YYYY');
    },
  },
  watch: {
    panel() {
      if (this.panel == undefined) {
        this.panel = 0;
      }
    },
    gender() {
      if (this.gender == 'Male') {
        this.Items = this.menItems;
      } else {
        this.Items = this.womenItems;
      }

      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss">
.select {
  width: 140px;
  max-height: 60px;
  font-size: 11px;
}
.Create-btn {
  color: white !important;
}
.measurement-section {
  overflow-y: scroll;
  height: 600px;
  overflow-x: hidden;
}
</style>
