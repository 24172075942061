<template>
  <div class="">
    <div class="heading-sec">
      <div class="col-md-4 mt-6">
        <div class="poppins-medium font-30 heading-profile ml-7">Wishlist</div>
      </div>
    </div>
    <div class="panel-content bg-white border">
      <div class="row">
        <div class="col-md-12 mt-4">
          <div class="font-20 widget-title float-left ml-10">
            List of Wishlist
            <span v-if="wishList" class="tableTotal font-15 poppins-bold"
              >(Total : {{ wishList.length }})</span
            >
          </div>
          <div class="float-right d-flex mx-4">
            <div>
              <v-dialog v-model="dialog" width="50%">
                <template v-slot:activator="{ on, attrs }">
                  <b-button variant="secondary" v-bind="attrs" v-on="on">
                    Filter
                    <b-icon-filter></b-icon-filter>
                  </b-button>
                </template>

                <v-card>
                  <div class="form-elements-sec">
                    <div class="form-body">
                      <form name="details" class="formPart text-left">
                        <div>
                          <div class="form-group mx-4 pt-4">
                            <label for="gender">Gender</label>
                            <input
                              type="name"
                              class="form-control"
                              id="name"
                              placeholder="Enter Gender"
                              v-model="wishlistFilter.gender"
                            />
                          </div>
                        </div>

                        <div>
                          <div class="form-group mx-4 pt-4">
                            <label for="Type">Type</label>
                            <input
                              type="number"
                              class="form-control"
                              id="number"
                              placeholder="Enter Type"
                              v-model="wishlistFilter.type"
                            />
                          </div>
                        </div>

                        <div>
                          <div class="form-group mx-4 pt-4">
                            <label for="mobile">Category</label>
                            <v-select
                              :items="categories"
                              label="Category"
                              dense
                              solo
                              v-model="wishlistFilter.category"
                            ></v-select>
                          </div>
                        </div>

                        <div>
                          <div class="form-group mx-4 pt-4">
                            <label for="name">Name</label>
                            <input
                              type="name"
                              class="form-control"
                              id="name"
                              placeholder="Enter Product Name"
                              v-model="wishlistFilter.name"
                            />
                          </div>
                        </div>
                        <div>
                          <div class="form-group mx-4 pt-4">
                            <label for="brandName">Brand Name</label>
                            <input
                              type="text"
                              class="form-control"
                              id="name"
                              placeholder="Enter Brand Name"
                              v-model="wishlistFilter.brandName"
                            />
                          </div>
                        </div>
                        <div>
                          <div class="form-group mx-4 pt-4">
                            <label for="fromPrice">From Price</label>
                            <input
                              type="number"
                              class="form-control"
                              id="name"
                              placeholder="Enter From Price"
                              v-model="wishlistFilter.fromPrice"
                            />
                          </div>
                        </div>
                        <div>
                          <div class="form-group mx-4 pt-4">
                            <label for="toPrice">To Price</label>
                            <input
                              type="name"
                              class="form-control"
                              id="name"
                              placeholder="Enter To Price"
                              v-model="wishlistFilter.toPrice"
                            />
                          </div>
                        </div>

                        <div class="btnPart text-center py-4">
                          <button
                            type="submit"
                            class="btn btn-success px-6"
                            @click="getUserWishlist()"
                          >
                            Save
                          </button>

                          <button type="reset" class="btn btn-danger mx-2" v-on:click="resetForm()">
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </v-card>
              </v-dialog>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-center" align="center">
        <div class="col-md-12">
          <div class="widget">
            <div class="table-area">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th class="col-md-1 text-center">#</th>
                      <th class="col-md-2">Date</th>
                      <th class="col-md-2">Palettier SKU Code</th>
                      <th class="col-md-3">Name</th>

                      <th class="col-md-2">BrandName</th>

                      <!-- <th class="col-md-2">Price</th>

                      <th class="col-md-2">Type</th>
                      <th class="col-md-2">Category</th> -->
                      <th class="col-md-2">Gender</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in wishList" v-bind:key="item._id">
                      <td class="text-center">
                        {{ index + 1 + (page - 1) * 20 }}
                      </td>
                      <td>
                        <div>{{ item.createdAt | moment }}</div>
                      </td>
                      <td>
                        <div>{{ item.productData.sku }}</div>
                      </td>
                      <td>
                        <div>{{ item.productData.name }}</div>
                      </td>
                      <td>
                        <div>{{ item.userData[0].brandName }}</div>
                      </td>
                      <!-- <td>
                        <div>{{ item.productData.sellingPrice }}</div>
                      </td>

                      <td>
                        <div>{{ item.productData.type }}</div>
                      </td>
                      <td>
                        <div>{{ item.productData.productCategory }}</div>
                      </td> -->
                      <td>
                        <div>{{ item.productData.category }}</div>
                      </td>

                      <!-- v-repeat -->
                    </tr>
                    <div></div>
                    <!-- end v-repeat -->
                  </tbody>
                </table>
              </div>
              <div
                class="table-body-contents text-center m-3 font-size-md font-weight-bold text-muted"
                v-if="!wishList"
              >
                No Data Found
              </div>

              <!-- Use text in props -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center mt-4" v-if="wishList">
      <v-pagination
        class="mb-2 float-center"
        v-model="page"
        :length="totalPages"
        @input="getUserWishlist()"
        :per-page="20"
        prev-icon="<"
        next-icon=">"
      />
    </div>
  </div>
</template>

<script>
import cutomerService from '../../services/customerService';
import moment from 'moment';
export default {
  data() {
    return {
      searchText: '',
      dialog: false,
      wishList: [
        {
          name: 'User 1',
          quantity: 2,
          price: '40000',
          status: 'active',
          date: '02/Feb/2022',
        },
        {
          name: 'User 1',
          quantity: 2,
          price: '40000',
          status: 'active',
          date: '02/Feb/2022',
        },
      ],

      page: 1,
      totalPages: '',
      productPrice: 0,
      wishlistFilter: {
        gender: '',
        type: '',
        category: '',
        name: '',
        brandName: '',
        fromPrice: 1,
        toPrice: 5000,
      },
      categories: ['Lehenga', 'Blouse', 'Dupatta', 'Bottom', 'Saree', 'Dress', 'Kurta', 'Jacket'],
    };
  },
  created() {
    this.getUserWishlist();
  },

  methods: {
    async getUserWishlist() {
      this.dialog = false;
      const id = this.$route.params.id;
      const data = {};
      data.page = this.page;
      data.filter = this.wishlistFilter;
      const result = await cutomerService.getUserAllWishlist(id, data);

      this.wishList = result.data.data;
      console.log('wishlist: ', this.wishList);
      this.totalPages = result.data.paginationData.totalPages;
    },
    resetForm() {
      this.wishlistFilter = {
        gender: '',
        type: '',
        category: '',
        name: '',
        brandName: '',
        fromPrice: 1,
        toPrice: 5000,
      };
      this.dialog = false;
      this.getUserWishlist();
    },
  },
  filters: {
    moment(date) {
      return moment(date).format('DD-MM-YYYY');
    },
  },
};
</script>

<style lang="scss">
.select {
  width: 140px;
  max-height: 60px;
  font-size: 11px;
}
.Create-btn {
  color: white !important;
}
</style>
