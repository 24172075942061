<template>
  <div>
    <div class="mx-4 mt-16 justify-center" align="center">
      <v-tabs v-model="tab" class="tab-section-card" fixed-tabs height="35px" show-arrows>
        <v-tabs-slider color="#72593F" class="tabs-slider-none"></v-tabs-slider>
        <v-tab v-for="item in items" :key="item.tab" :href="'#' + item.name">
          {{ item.name }}
        </v-tab>
      </v-tabs>
    </div>
    <v-tabs-items v-model="tab" class="mt-3">
      <v-tab-item v-for="item in items" :key="item.tab" :value="item.name">
        <div v-if="item.tab === 'USER'">
          <UserCard />
        </div>

        <div v-if="item.tab === 'CUSTOMLOOK'">
          <SavedLook />
        </div>
        <div v-if="item.tab === 'WISHLIST'">
          <Wishlist />
        </div>
        <div v-if="item.tab === 'SIZE'">
          <Size />
        </div>
        <!-- <div v-if="item.tab === 'CART'">
          <Cart />
        </div>
        <div v-if="item.tab === 'ORDERS'">
          <Orders />
        </div>
        <div v-if="item.tab === 'ADDRESS'">
          <Address />
        </div>
        <div v-if="item.tab === 'STYLEPROFILES'">
          <StyleProfile />
        </div> -->
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
// import cutomerService from "../../services/customerService";
import UserCard from '@/components/Users/UserCard.vue';
// import Cart from '@/components/Users/Cart.vue';
// import Orders from '@/components/Users/Orders.vue';
// import Address from '@/components/Users/Addresses.vue';
import Wishlist from '@/components/Users/Wishlist.vue';
import SavedLook from '@/components/Users/SaveLook.vue';
// import StyleProfile from '@/components/Users/StyleProfile.vue';
import Size from '@/components/Users/Size.vue';

export default {
  components: {
    UserCard,
    // Cart,
    // Orders,
    // Address,
    Wishlist,
    SavedLook,
    // StyleProfile,
    Size,
  },
  data() {
    return {
      tab: null,
      items: [
        { tab: 'USER', name: 'User' },
        // { tab: 'CART', name: 'Cart' },
        // { tab: 'ORDERS', name: 'Orders' },
        // { tab: 'ADDRESS', name: 'Address' },
        { tab: 'CUSTOMLOOK', name: 'Custom Looks' },
        { tab: 'WISHLIST', name: 'Wishlist' },
        { tab: 'SIZE', name: 'My Size & Fit' },
        // { tab: 'STYLEPROFILES', name: 'Style Profiles' },
      ],
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.tab-section-card {
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  .v-tab--active {
    background: black;
    color: white !important;
    border-radius: 6px 6px 6px 6px;
  }
}
.tabs-slider-none {
  display: none;
}
@media only screen and (min-width: 960px) {
  .tab-section {
    width: 100%;
  }
}
</style>
