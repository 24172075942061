<template>
  <div>
    <div></div>
    <div>
      <div class="font-24 mt-10 ml-4">Edit User</div>
      <div class="justify-center" align="center">
        <!-- Top Bar Chart -->
        <v-card class="mt-2 mx-4">
          <div class="panel-content">
            <div class="row">
              <div class="col-md-12">
                <div class="widget">
                  <div class="form-elements-sec">
                    <div class="form-body">
                      <form
                        ref="userForm"
                        name="details"
                        class="formPart text-left"
                        @submit.prevent="submitForm(id)"
                      >
                        <!-- Name -->
                        <div>
                          <div
                            class="form-group col"
                            :class="{
                              'form-group--error': $v.details.name.$error,
                            }"
                          >
                            <label for="name">Name:</label>
                            <input
                              type="name"
                              class="form-control"
                              id="name"
                              placeholder="Enter Name"
                              v-model.trim="details.name"
                              @input="setTouch('name')"
                            />
                            <div
                              class="validation"
                              v-if="!$v.details.name.required && $v.details.name.$error"
                            >
                              Name is required
                            </div>
                          </div>
                        </div>
                        <!-- Email -->
                        <div>
                          <div
                            class="form-group col"
                            :class="{
                              'form-group--error': $v.details.email.$error,
                            }"
                          >
                            <label for="email">Email:</label>
                            <input
                              type="email"
                              class="form-control"
                              id="email"
                              placeholder="name@gmail.com"
                              v-model.trim="details.email"
                              @input="setTouch('email')"
                            />
                            <div
                              class="validation"
                              v-if="!$v.details.email.required && $v.details.email.$error"
                            >
                              Email is required
                            </div>
                          </div>
                        </div>
                        <!-- phone no -->
                        <div>
                          <div
                            class="form-group col"
                            :class="{
                              'form-group--error': $v.details.mobile.$error,
                            }"
                          >
                            <label for="mobile">Mobile No:</label>
                            <input
                              type="number"
                              class="form-control"
                              id="mobile"
                              placeholder="Enter Mobile No"
                              v-model.trim="details.mobile"
                              @input="setTouch('mobile')"
                            />
                            <div
                              class="validation"
                              v-if="!$v.details.mobile.required && $v.details.mobile.$error"
                            >
                              Mobile No is required
                            </div>
                          </div>
                        </div>
                        <!--Gender-->
                        <div class="ml-4">
                          <label for="gender"> Gender</label>

                          <div class="d-flex">
                            <div>
                              <input
                                type="radio"
                                v-model="details.gender"
                                value="Male"
                                class="mr-1"
                              />
                              <label>Male</label>
                            </div>
                            <div class="ml-6">
                              <input
                                type="radio"
                                v-model="details.gender"
                                value="Female"
                                class="mr-1"
                              />
                              <label>Female</label>
                            </div>
                          </div>
                        </div>
                        <!--Date of Birth-->
                        <div>
                          <div
                            class="form-group col"
                            :class="{
                              'form-group--error': $v.details.dateOfBirth.$error,
                            }"
                          >
                            <label for="dateOfBirth">Date of Birth:</label>
                            <input
                              type="date"
                              class="form-control"
                              id="dateOfBirth"
                              placeholder="Date of birth"
                              v-model="details.dateOfBirth"
                              @input="setTouch('dateOfBirth')"
                            />
                            <div
                              class="validation"
                              v-if="
                                !$v.details.dateOfBirth.required && $v.details.dateOfBirth.$error
                              "
                            >
                              Date Of Birth is required
                            </div>
                          </div>
                        </div>

                        <!--Location-->
                        <div>
                          <div
                            class="form-group col"
                            :class="{
                              'form-group--error': $v.details.location.$error,
                            }"
                          >
                            <label for="name">Location:</label>
                            <input
                              type="name"
                              class="form-control"
                              id="name"
                              placeholder="Enter Location"
                              v-model.trim="details.location"
                              @input="setTouch('location')"
                            />
                            <div
                              class="validation"
                              v-if="!$v.details.location.required && $v.details.location.$error"
                            >
                              Location is required
                            </div>
                          </div>
                        </div>
                        <!-- button -->
                        <div class="btnPart text-center my-4">
                          <button type="submit" class="btn btn-success">Save</button>
                          <p class="typo__p" v-if="submitStatus === 'OK'">
                            Thanks for your submission!
                          </p>
                          <p class="typo__p" v-if="submitStatus === 'ERROR'">
                            Please fill the form correctly.
                          </p>
                          <button type="reset" class="btn btn-danger mx-3" v-on:click="resetForm()">
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import customerService from '../../services/customerService';
import moment from 'moment';

export default {
  components: {},
  data() {
    return {
      details: {
        name: '',
        email: '',
        mobile: '',
        dateOfBirth: '',
        gender: '',
        location: '',
      },
      submitStatus: false,
      id: '',
    };
  },
  validations: {
    details: {
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      mobile: {
        required,
      },
      dateOfBirth: {
        required,
      },
      gender: {
        required,
      },
      location: {
        required,
      },
    },
  },
  created() {
    this.id = this.$route.params.id;
    if (this.id) {
      this.getOneUser(this.id);
    }
  },
  methods: {
    setTouch(fields) {
      if (fields === 'name') {
        this.$v.details.name.$touch();
      } else if (fields === 'email') {
        this.$v.details.email.$touch();
      } else if (fields === 'mobile') {
        this.$v.details.mobile.$touch();
      } else if (fields === 'gender') {
        this.$v.details.gender.$touch();
      } else if (fields === 'dateOfBirth') {
        this.$v.details.dateOfBirth.$touch();
      } else if (fields === 'location') {
        this.$v.details.location.$touch();
      }
    },
    // this is use for save button
    async submitForm(id) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$store.commit('setSnackbar', {
          content: 'please fill form correctly',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });

        this.submitStatus = 'false';
      } else {
        console.log(id, 'id');
        this.submitStatus = 'true';
        const data = this.details;
        delete data.email;
        const result = await customerService.updateUserData(id, data);
        if (result.status === 200) {
          this.$store.commit('setSnackbar', {
            content: 'Submitted Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
          this.$router.push('/viewUsers');
        }
        console.log(result);
      }
    },
    async getOneUser(id) {
      console.log(id, 'id');
      const result = await customerService.getOneCustomer(id);
      this.details.name = result.data.name;
      this.details.email = result.data.email;
      this.details.mobile = result.data.mobile;
      this.details.gender = result.data.gender;
      if (moment(result.data.dateOfBirth, 'YYYY-MM-DD', true).isValid()) {
        this.details.dateOfBirth = moment(result.data.dateOfBirth).format('YYYY-MM-DD');
      } else {
        this.details.dateOfBirth = result.data.dateOfBirth;
      }
      this.details.location = result.data.location;
      console.log(result, 'result');
    },

    resetForm() {
      this.details = {
        name: '',
        email: '',
        mobile: '',
      };
      this.$router.push('/viewUsers');
    },
  },
};
</script>

<style lang="scss" scoped>
.validation {
  color: red;
}
</style>
