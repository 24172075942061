<template>
  <div class="">
    <div class="heading-sec">
      <div class="col-md-4 mt-6">
        <div class="poppins-medium font-30 heading-profile ml-7">Custom Looks</div>
      </div>
    </div>
    <div class="panel-content bg-white border">
      <div class="row">
        <div class="col-md-12 mt-4">
          <div class="font-20 widget-title float-left ml-10">
            List of Custom Looks
            <span v-if="savedLooks && savedLooks" class="tableTotal font-15 poppins-bold"
              >(Total : {{ savedLooks.length }})</span
            >
          </div>
          <div class="float-right d-flex mx-4">
            <div>
              <v-dialog v-model="dialog" width="50%">
                <template v-slot:activator="{ on, attrs }">
                  <b-button variant="secondary" v-bind="attrs" v-on="on">
                    Filter
                    <b-icon-filter></b-icon-filter>
                  </b-button>
                </template>

                <v-card>
                  <div class="form-elements-sec">
                    <div class="form-body">
                      <form name="details" class="formPart text-left">
                        <div>
                          <div class="form-group mx-4 pt-4">
                            <label for="name">Name</label>
                            <input
                              type="name"
                              class="form-control"
                              id="name"
                              placeholder="Enter Name"
                              v-model="filter.name"
                            />
                          </div>
                        </div>

                        <div>
                          <div class="form-group mx-4 pt-4">
                            <label for="mobile">Quantity</label>
                            <input
                              type="number"
                              class="form-control"
                              id="mobile"
                              placeholder="Enter Quantity"
                              v-model="filter.quantity"
                            />
                          </div>
                        </div>

                        <v-row class="mt-3 mx-2">
                          <v-col class="">
                            <v-select
                              :items="categories"
                              label="Category"
                              dense
                              solo
                              v-model="filter.category"
                            ></v-select>
                          </v-col>
                          <v-col class="">
                            <v-select
                              :items="gender"
                              label="Gender"
                              dense
                              solo
                              v-model="filter.gender"
                            ></v-select>
                          </v-col>
                        </v-row>

                        <div class="btnPart text-center py-4">
                          <button
                            @click="getUserSavedLooks()"
                            type="submit"
                            class="btn btn-success px-6"
                          >
                            Save
                          </button>

                          <button type="reset" class="btn btn-danger mx-2" v-on:click="resetForm()">
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </v-card>
              </v-dialog>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-center" align="center">
        <div class="col-md-12">
          <div class="widget">
            <div class="table-area">
              <div class="table-responsive">
                <table class="table text-center">
                  <thead>
                    <tr>
                      <th class="col-md-1">#</th>

                      <th class="col-md-1">Date</th>
                      <th class="col-md-2">Request for Call Back</th>
                      <th class="col-md-2">Palettier SKU Code</th>
                      <th class="col-md-2">Name</th>

                      <th class="col-md-2">BrandName</th>

                      <th class="col-md-1">Gender</th>
                      <th class="col-md-1">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in savedLooks" v-bind:key="item._id">
                      <td>
                        {{ index + 1 + (page - 1) * 20 }}
                      </td>

                      <td>
                        <div v-if="item.createdAt">{{ item.createdAt | moment }}</div>
                      </td>
                      <td>
                        <div v-if="item.requestForCall">Yes</div>
                        <div v-else>No</div>
                      </td>
                      <td>
                        <div
                          v-if="
                            item.customization &&
                            item.customization.product &&
                            item.customization.product.sku
                          "
                        >
                          {{ item.customization.product.sku }}
                        </div>
                      </td>
                      <td>
                        <div
                          v-if="
                            item.customization &&
                            item.customization.product &&
                            item.customization.product.name
                          "
                        >
                          {{ item.customization.product.name }}
                        </div>
                      </td>
                      <td>
                        <div
                          v-if="
                            item.customization &&
                            item.customization.product &&
                            item.customization.product.vendor &&
                            item.customization.product.vendor.brandName &&
                            item.customization.product.vendor.brandName
                          "
                        >
                          {{ item.customization.product.vendor.brandName }}
                        </div>
                      </td>
                      <td>
                        <div
                          v-if="
                            item.customization &&
                            item.customization.product &&
                            item.customization.product.category
                          "
                        >
                          {{ item.customization.product.category }}
                        </div>
                      </td>
                      <td>
                        <v-btn dense @click="showCustomisation(item)"> View</v-btn>
                      </td>
                      <!-- v-repeat -->
                    </tr>
                    <div></div>
                    <!-- end v-repeat -->
                  </tbody>
                </table>
              </div>
              <div
                class="table-body-contents text-center m-3 font-size-md font-weight-bold text-muted"
                v-if="savedLooks.length == 0"
              >
                No Data Found
              </div>

              <!-- Use text in props -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center mt-4" v-if="savedLooks">
      <v-pagination
        class="mb-2 float-center"
        v-model="page"
        :length="totalPages"
        @input="getUserSavedLooks()"
        :per-page="20"
        prev-icon="<"
        next-icon=">"
      />
    </div>
    <v-row justify="center">
      <v-dialog v-model="view" scrollable max-width="650px" min-height="850px">
        <v-card class="px-4 justify-center" align="center">
          <v-row no-gutters class="text-center" align="center">
            <v-col cols="2"></v-col>
            <v-col cols="8"><div class="h4 text-center mt-2 pt-2">Saved Custom Look</div></v-col>
            <v-col cols="2"
              ><div class="float-right">
                <img src="@/assets/Images/close.svg" @click="view = false" /></div
            ></v-col>
          </v-row>
          <v-divider></v-divider>
          <div class="text-left d-flex ml-3">
            <div class="font-16 poppins-700 maroon-txt mr-3">Request For Call Back:</div>
            <div class="font-16 font-weight-bold" v-if="requestForCallBack">Yes</div>
            <div class="font-16 font-weight-bold" v-else>No</div>
          </div>
          <div class="text-left d-flex  ml-3">
            <div class="font-16 poppins-700 maroon-txt mr-3">Note:</div>
            <div class="font-16 font-weight-bold">{{ note }}</div>
          </div>
          <div class="table-area mt-5">
            <div class="table-responsive">
              <table class="table">
                <tbody class="border">
                  <tr
                    v-for="(category, index) in saveLookCustomisation"
                    v-bind:key="index"
                    class="border"
                  >
                    <td class="border">
                      <div class="font-16 poppins-700 maroon-txt">{{ category.name }}</div>
                    </td>
                    <td v-for="(layer, index) in category.layers" v-bind:key="index">
                      <div class="font-16 poppins-medium grey--text">
                        {{ layer.name }}
                      </div>
                      <div class="font-14 font-weight-bold">
                        {{ layer.toggles[layer.selectedToggle].name }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="text-left d-flex ml-3">
            <div class="font-16 poppins-700 maroon-txt mr-3">Customisation Image:</div>
            <div>
              <v-img :src="customizationImage" width="200px" height="300px"></v-img>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import customerService from '../../services/customerService';
import moment from 'moment';

export default {
  data() {
    return {
      searchText: '',
      savedLooks: [
        {
          name: 'User 1',
          quantity: 1,
          price: 40000,
          delivery: 'Yes',
          category: 'Single',
          date: '16-May-2022',
          gender: 'Male',
        },
        {
          name: 'User 1',
          quantity: 1,
          price: 40000,
          delivery: 'Yes',
          category: 'Single',
          date: '16-May-2022',
          gender: 'Male',
        },
      ],

      page: 1,
      dialog: false,
      totalPages: '',
      productPrice: 0,
      filter: {
        category: '',
        name: '',
        quantity: '',
        gender: '',
      },
      categories: ['Single', 'Set'],
      gender: ['Male', 'Female'],
      view: false,
      saveLookCustomisation: [],
      requestForCallBack: '',
      note: '',
      customizationImage: '',
    };
  },
  created() {
    this.getUserSavedLooks();
  },

  methods: {
    async getUserSavedLooks() {
      this.dialog = false;
      const data = {};
      data.page = this.page;
      data.filter = this.filter;
      const result = await customerService.getUserAllSavedLooks(this.$route.params.id, data);
      this.savedLooks = [];
      this.savedLooks = result.data.data;
      this.totalPages = result.data.paginationData.totalPages;
      console.log('result', result);
      console.log('this.route', this.$router.path);
    },
    resetForm() {
      this.filter = {
        category: '',
        name: '',
        quantity: '',
        gender: '',
      };
      this.dialog = false;
      this.getUserSavedLooks();
    },

    async showCustomisation(item) {
      let itemCustomisation = item.customization.categories;
      this.requestForCallBack = item.requestForCall;
      this.note = item.note;
      this.customizationImage = item.customizationImage;
      this.view = true;
      this.saveLookCustomisation = await itemCustomisation;
      console.log(this.saveLookCustomisation, 'savelookcustomisation');
    },
  },
  filters: {
    moment(date) {
      return moment(date).format('DD-MM-YYYY');
    },
  },
};
</script>

<style lang="scss">
.select {
  width: 140px;
  max-height: 60px;
  font-size: 11px;
}
.Create-btn {
  color: white !important;
}
</style>
